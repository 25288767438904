import { useTranslation } from 'react-i18next';
import TwinPane from '../TwinPane/index.js';
import './index.css';
import React, {useState, useEffect, useRef, useImperativeHandle} from 'react';
import { ActionTypes } from '@smarterservices/twilio-sync-client';

import { checkPermissions, pTranslationArray } from '../../helpers/Helpers';
import * as icons from "../Icons";

function ChromePermissionCheck(props, ref) {
  const { t: translate } = useTranslation();
  const translation = translate('chrome_permission_check');

  const firstRender = useRef(true);
  const [camera, setCamera] = useState(false);
  const [mic, setMic] = useState(false);
  const [images, setImages] = useState(false);
  const [popups, setPopups] = useState(false);
  const [examUrl, setExamUrl] = useState(null);
  const [waitForStep, setWaitForStep] = useState(false);
  const intervalRef = useRef(true);
  const { setLoading, setNextEnabled, syncClient, throwError, loading } = props;

  const actionTypes = new ActionTypes();

  useImperativeHandle(ref, () => {
    return {
      emitterCallback: (actionObj) => emitterCallback(actionObj),
      waitForStep: waitForStep,
    }
  })
  const enablePermissions = () => {
    setWaitForStep(true);
    const actions = [
        actionTypes.extension.enablePermissions(examUrl),
        actionTypes.extension.getAllowedPermissions(examUrl, 'onboarding')
    ];
    syncClient.addActions(actions);
    // Sets flag to render the ChromePermission component after reload
  }

  const setPermissionState = (permissions) => {
    permissions.indexOf('camera') !== -1 ? setCamera(true) : setCamera(false);

    permissions.indexOf('microphone') !== -1 ? setMic(true) : setMic(false);

    permissions.indexOf('images') !== -1 ? setImages(true) : setImages(false);

    permissions.indexOf('popups') !== -1 ? setPopups(true) : setPopups(false);
  }

  const emitterCallback = actionObj => {
    const permissions = checkPermissions(actionObj.response);
    setPermissionState(permissions);
    setLoading(loading.filter(item => item !== 'permissionsStep'))
    intervalRef.current = false;
  }

  useEffect(() => {
    const getPermissions = async () => {
      const syncDoc = await syncClient.getSync();
      setExamUrl(syncDoc.sessionStart.exam.url);
      syncClient.addAction(actionTypes.extension.getAllowedPermissions(syncDoc.sessionStart.exam.url, 'onboarding'));
    }

    if(syncClient && firstRender.current && loading.length <= 1) {
      firstRender.current = false;
      getPermissions();
      setLoading([...loading, 'permissionsStep']);
      setTimeout(() => {
        if(loading.length > 1){
          setLoading(loading.filter(item => item !== 'permissionsStep'))
          throwError(translation.errors.general);
        } else{
          return
        }

      }, 10000)
    }
  }, [setLoading, syncClient, throwError, actionTypes.extension, translation.errors.general, loading])

  useEffect(() => {
    // If all chrome permissions are good, then proceed to next step
    if(camera && mic && images && popups) {
      setNextEnabled(true);
    } else {
      setNextEnabled(false);
    }

  })

  useEffect(() => {
    if(syncClient)
    {
     syncClient.setSync({ currentStep: "ChromePermissionCheck" })
    }

    //eslint-disable-next-line
  }, [])

  const renderLeftPane = () => {
    if(camera && mic && images && popups){
      return(
          <div>
            <p>{pTranslationArray(translation.left_pane.success)}</p>
          </div>
      )
    }
    else{
      return (
          <article className="permissions-pane">
            <h2>{translation.step_one}</h2>
            {pTranslationArray(translation.left_pane.step_1)}

            <h2>{translation.step_two}</h2>
            {pTranslationArray(translation.left_pane.step_2)}
          </article>
      )
    }
  }

  const renderRightPane = () => {
    if(camera && mic && images && popups){
      return(
        <div className='flex-div'>
          <icons.iconCheckCircle />
          <h2 className="center-text">{translation.right_pane.enabled}</h2>
        </div>
      )
    }
    else{
      return (
        <article>
          <h3 className="perm-title">{translation.right_pane.need_corrected}</h3>
          <div className="perm-loader">
            <ol className="perm">
              <li key={1}>
                <strong>{translation.right_pane.camera}</strong>
                <span className={`perm__status ${camera ? 'completed' : 'in-progress'}`}></span>
                <span className="meta"></span>
              </li>
              <li key={2}>
                <strong>{translation.right_pane.mic}</strong>
                <span className={`perm__status ${mic ? 'completed' : 'in-progress'}`}></span>
                <span className="meta"></span>
              </li>
              <li key={3}>
                <strong>{translation.right_pane.images}</strong>
                <span className={`perm__status ${images ? 'completed' : 'in-progress'}`}></span>
                <span className="meta"></span>
              </li>
              <li key={4}>
                <strong>{translation.right_pane.pop_ups}</strong>
                <span className={`perm__status ${popups ? 'completed' : 'in-progress'}`}></span>
                <span className="meta"></span>
              </li>
            </ol>
          </div>

          <button
              className='btn'
              disabled={!examUrl}
              onClick={enablePermissions}
          >
            {translation.enable_permissions}
          </button>
        </article>
      )
    }
  }

  return <TwinPane leftPane={renderLeftPane()} rightPane={renderRightPane()} />;
}

export  default  React.forwardRef(ChromePermissionCheck)