import './index.css';
import TwinPane from '../TwinPane';
import { useTranslation } from 'react-i18next';
import React, {useEffect, useImperativeHandle, useState} from 'react';
import { toHours } from '../../helpers/Helpers';
import { getModalityOptions } from '../Stepper/api';
import PermittedItems from '../PermittedItems';
import { ActionTypes, AnnotationTypes, ModalityOptions } from '@smarterservices/twilio-sync-client';

function PreparingSession(props, ref) {
  const { t: translate } = useTranslation();
  const translation = translate('preparing_session');
  const { params, setNextEnabled, studentNotes, syncClient } = props;
  const [taskStatus, setTaskStatus] = useState('pending');
  const [syncDoc, setSyncDoc] = useState(null);
  const actionTypes = new ActionTypes();
  let responded = false;
  const { installSid, examSid, sessionSid, token } = params;


  useImperativeHandle(ref, () => {
    return{
      emitterCallback: (syncDoc) => emitterCallback(syncDoc)
    }
  })


  const handleSessionStart = async (examUrl) => {
    const optionsToApply = [];
    const actions = [
      actionTypes.extension.changeIconColor('red'),
      actionTypes.extension.showFloatingMenu()
    ];
    const response = await getModalityOptions(installSid, examSid, sessionSid, token);
    const { modalityOptions } = response;
    if (modalityOptions.forceFullScreen) optionsToApply.push(ModalityOptions.forceFullScreen());
    if (modalityOptions.disableNewTabs) optionsToApply.push(ModalityOptions.disableNewTabs([examUrl]));

    if (optionsToApply.length > 0) {
      const optionNames = optionsToApply.map(option => {
        return option.name
      })
      actions.push(actionTypes.extension.applyModalityOptions(optionsToApply));
      actions.push(actionTypes.session.createAnnotation(AnnotationTypes.lockdown(optionNames)));
    }
    syncClient.addActions(actions);
    setTaskStatus('complete');
    setNextEnabled(true);

    // Focuses the element explaining that the session is ready. We want to make sure that users
    // using a screen reader are also aware of when they can continue to the next step.
    document.getElementById('session_prepared').focus();
  }

  useEffect(()=> {
    const getSyncDoc = async() => {
      if (syncClient) {
        const syncDoc = await syncClient.getSync();
        setSyncDoc(syncDoc);
        if (syncDoc.faceProcessorStarted) {
          const examUrl = syncDoc.sessionStart.exam.url;
          await handleSessionStart(examUrl)
        }
      }
    }
    if (syncClient) {
      getSyncDoc();
    }
    //eslint-disable-next-line
  }, [syncClient, setNextEnabled, actionTypes.extension])

  const emitterCallback = (syncDoc) => {
    if (syncDoc.faceProcessorStarted && !responded) {
      // eslint-disable-next-line
      responded = true;
      handleSessionStart()
    } else if (!syncDoc.faceProcessorStarted) {
      setTaskStatus('pending');
      setNextEnabled(false);
    }
  }

  useEffect(() => {
    syncClient.setSync({ currentStep: "PreparingSession" })
    //eslint-disable-next-line
  },[])

  const renderProgressBar = () => {
    return (
      <div className='progress-bar'>
        <div className={`${taskStatus}`} >
          {taskStatus !== 'complete' && <div className='idle-animation' />}
          {taskStatus === 'complete' &&
            <strong>{translation.prep_complete}</strong>
          }
        </div>
      </div>
    )
  }

  const renderSessionDetails = () => {
    const {exam} = syncDoc.sessionStart;
    if(!exam) {
      return (
        <p>{translation.exam.no_exam_found}</p>
      )
    }

    return (
      <div className='session-details'>
          <h2>{translation.exam.exam_details}</h2>

          <ul className='data session-details'>
            <li>
              <strong>{translation.exam.name}</strong> {exam.title}
            </li>
            {/* Removed descriptions from the instructor side. We should probably hide them here as well */}
            {/* <li>
              <strong>{translation.exam.description}</strong> {exam?.description ? exam.description : translation.exam.no_description }
            </li> */}
            <li>
              <strong>{translation.exam.duration}</strong> {toHours(exam.configuration.duration)}
            </li>
            <li className='scroll-box'>
              <strong>{translation.exam.student_notes}</strong> {studentNotes}
            </li>
          </ul>

          <PermittedItems configuration={exam.configuration} />
        </div>
    )
  }

  const renderLeftPane = () => {
    if (taskStatus === 'complete') {
      return (
        <div className='flex-container'>
          <p id='session-prepared' tabIndex={0}>{translation.session_prepared}</p>
        </div>
      )
    } else {
      return (
        <div className='flex-container'>
        <p>{translation.left_pane.content[0]}</p>
      </div>
      )
    }
  }

  const renderRightPane = () => {
    return (
      <div className='flex-container'>
        {renderProgressBar()}

        {/*
          We need to make sure we have the syncDoc before trying to
          render session details so that we don't get any errors
        */}
        {syncDoc &&
          renderSessionDetails()
        }
      </div>
    )
  }

  return (
    <>
      <TwinPane leftPane={renderLeftPane()} rightPane={renderRightPane()} />
    </>
  );
} export default React.forwardRef(PreparingSession);