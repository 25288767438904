import React from 'react';
import PhotoVerification from "../PhotoVerification";

export default function IdentityVerification(props) {
  const { syncClient, setNextEnabled, throwError } = props;

  return (
    <PhotoVerification
      throwError = {throwError}
      translationComponent={"id_verification"}
      type={"idFront"}
      setNextEnabled={setNextEnabled}
      syncClient={syncClient}
      />
  );
}