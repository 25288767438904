import './index.css';
import { useTranslation } from 'react-i18next';

export default function TwinPane(props) {
  const { t: translation } = useTranslation();
  if(!props.leftPane || !props.rightPane) {
    return <>{translation("general.missing_panes")}</>
  }

  return (
    <div className="twin-pane-container">
      <section className="leftPane">{props.leftPane}</section>
      <section className="rightPane">{props.rightPane}</section>
    </div>
  )
}