import { useTranslation } from 'react-i18next';
import TwinPane from '../TwinPane/index.js';
import './index.css';
import React, {useEffect, useRef} from 'react';
import { getOperatingSystem, pTranslationArray } from '../../helpers/Helpers';

export default function CameraCheck(props) {
    const { t: translate } = useTranslation();
    const translation = translate('camera_check');
    const { setNextEnabled, setLoading, syncClient, throwError, loading } = props;

    const stream  = useRef(null);

    useEffect(() => {
        //Grab all the media devices and then render the options in the dropdown
        navigator.mediaDevices.enumerateDevices().then(renderWebcamOptions);
        // eslint-disable-next-line
        return () => {
            stopMediaTracks();
        }
        //eslint-disable-next-line
    },[])

    const stopMediaTracks = () => {
        stream?.current?.getTracks().forEach(track => {
            track.stop();
        });
    }

    const renderWebcamOptions = (mediaDevices) => {
        const select = document.getElementById('select');
        const option = document.createElement('option');
        const textNode = document.createTextNode(translation.please_select);
        option.appendChild(textNode);
        select.appendChild(option);

        mediaDevices.forEach(mediaDevice => {
            if (mediaDevice.kind === 'videoinput') {
                const option = document.createElement('option');
                option.value = JSON.stringify({ deviceId: mediaDevice.deviceId, name: mediaDevice.label });
                const label = mediaDevice.label;
                const textNode = document.createTextNode(label);
                option.appendChild(textNode);
                select.appendChild(option);
            }
        })
    }

    const selectWebcam = (e) => {
        // Reset next button in case of camera change
        setNextEnabled(false);

        const video = document.getElementById('video');

        if(e.target.value === translation.please_select){
            stopMediaTracks()
            video.srcObject = null;
            return;
        }

        setLoading([...loading, 'selectWebcam']);

        // If stream.current isn't undefined we will stop all tracks of previous stream
        const device = JSON.parse(e.target.value);
        const videoConstraints = { deviceId: device.deviceId };

        const constraints = {
            video: videoConstraints,
            audio: false
          };


        try {
            navigator.mediaDevices.getUserMedia(constraints)
                .then(media => {
                    stream?.current?.getTracks().forEach(track => {
                        track.stop();
                    });
                    // Set device to stream state
                    stream.current = media;
                    // Set the source of video element
                    video.srcObject = media;
                    video.onloadedmetadata = () => {
                        // Clear videoDeviceId in the syncDoc
                        syncClient.setSync({videoDeviceId: null})
                        video.play();
                        // Set webcam as videDeviceId in syncDoc
                        syncClient.setSync({videoDeviceId: e.target.value})
                        // Enable next button
                        setNextEnabled(true);
                        setLoading(loading.filter(item => item !== 'selectWebcam'))
                    }
                })
            }
            catch(error)  {
                setLoading(loading.filter(item => item !== 'selectWebcam'))
                if (error.name === 'NotAllowedError') {
                    const os = getOperatingSystem(window);
                    switch (os) {
                        case 'MacOS':
                            throwError(translation.errors.mac_permissions);
                            break;

                        case 'Windows OS':
                            throwError(translation.errors.windows_permissions);
                            break;

                        default:
                            throwError(translation.errors.default_permissions);
                            break;
                    }

                } else {
                    throwError(translation.errors.general);
                }
                setNextEnabled(false);
                return
            }
    }

    const renderLeftPane = () => {
        return (
            <article>
               <h2>{translation.step_1}</h2>
               <p>{translation.left_pane.step_1}</p>

               <h2>{translation.step_2}</h2>
               {pTranslationArray(translation.left_pane.step_2)}
            </article>
        )
    }

    const renderRightPane = () => {
        return (
            <article className="camera-check-panel">
                <select id="select" onChange={(e) => selectWebcam(e)} className="camera-dropdown" />
                <video className="webcam__stream" id="video" autoPlay/>
            </article>
        )
    }

    return(
        <>
            <TwinPane leftPane={renderLeftPane()} rightPane={renderRightPane()}/>
        </>
    );
}