import { config } from '../../config';

/**
 * getModalityOptions - Gets the modality options and workflow module for an exam session
 * so that we can remove unnecessary steps
 * @param {string} installSid (AI) Unique identifier for app installs
 * @param {string} examSid (EX) Unique identifier for exams
 * @param {string} sessionSid (ES) Unique identifier for exam sessions
 */
export async function getModalityOptions(installSid, examSid, sessionSid, token) {
  const url = `${config.apiUrl}/installs/${installSid}/exams/${examSid}/sessions/${sessionSid}/modalityOptions/configuration`;

  const options = {
    method: "GET",
    headers: {
      token: token
    }
  };

  const response = await fetch(url, options);
  return await response.json();
}

/**
 * Updates the session status to 'In Progress'
 * @param {string} sessionSid - Unique SID for the session to move to 'In Progress'
 * @param {string} token - Valid SP token
 * @returns {Promise<Response<any, Record<string, any>, number>>} - Promise that resolves to a fetch response object
 */
export async function putSessionInProgress(sessionSid, token) {
  const url = `${config.apiUrl}/sessions/${sessionSid}/inProgress`;
  const options = {
    method: 'POST',
    headers: {
      'token': token
    },
    body: JSON.stringify({})
  };

  return await fetch(url, options);
}


export async function connectUtilityApp(token, sessionSid, examSid, installSid){
  const url = `${config.desktopAppInfo.baseUrl}/accept/${token}/${sessionSid}/${installSid}/${examSid}`;
  const options = {
    method: 'POST',
    body: JSON.stringify({})
  };
  return await fetch(url, options)

}

export async function getUtilityAppVersion() {
  const url = `${config.desktopAppInfo.baseUrl}/version`;
  const options = {
    method: 'GET'
  };
  let data = await fetch(url, options);
  return await data.json();
}

export async function getUtilityAppMinimumVersion() {
  const url = `${config.desktopAppInfo.manifest}`;
  const options = {
    method: 'GET'
  };
  let data = await fetch(url, options);
  return await data.json();
}

export async function closeBlockedApplications(token, sessionSid, examSid, installSid) {
  const url = `${config.desktopAppInfo.baseUrl}/close-blocked-applications`
  const options = {
    method: 'POST',
    body: JSON.stringify({})
  };
  return await fetch(url, options)
}

export async function startSystemCheck(installSid, examSid, sessionSid, token) {
  const url = `${config.apiUrl}/installs/${installSid}/exams/${examSid}/sessions/${sessionSid}/systemcheck/start`;
  const options = {
    method: 'POST',
    headers: { token },
    body: JSON.stringify({})
  };
  return await fetch(url, options)
}

export async function completeSystemCheck(installSid, examSid, sessionSid, token) {
  const url = `${config.apiUrl}/installs/${installSid}/exams/${examSid}/sessions/${sessionSid}/systemcheck/complete`;
  const options = {
    method: 'POST',
    headers: { token },
    body: JSON.stringify({})
  };
  return await fetch(url, options)
}

export async function getSessionStartData(installSid, examSid, sessionSid, token) {
  const url = `${config.apiUrl}/installs/${installSid}/exams/${examSid}/sessions/${sessionSid}/start`;
  const options = {
    method: 'GET',
    headers: {
      token
    }
  }
  const data = await fetch(url, options)
  return await data.json();
}
export async function sendLocationIdentifiers(installSid, token) {
  const url = `${config.apiUrl}/installs/${installSid}/examDeliverySystems`;
  const options = {
    method: 'GET',
    headers: {
      token
    }
  }
  let data = await fetch(url, options)
  data = await data.json();
  return data.results;
}

export async function getExamConfig(installSid, courseSid, examSid, configurationSid, token) {
  const url = `${config.apiUrl}/installs/${installSid}/courses/${courseSid}/exams/${examSid}/configurations/${configurationSid}`;
  const options = {
    method: 'GET',
    headers: {
      token
    }
  };

  const response = await fetch(url, options);
  return await response.json();
}
