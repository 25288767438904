import { config } from '../../config';

/**
 * The endpoint should be used to upload the ID and face photos from the identity verification step.
 * @param {string} installSid - ID for the install
 * @param {string} examSid - ID for the exam
 * @param {string} sessionSid - ID for the session
 * @param {object} data - Data includes the type of the photo being taken, as well as the photo data itself
 * @param {string} token - the token for the current session
 * @returns
 */
export async function uploadSessionPhoto(installSid, examSid, sessionSid, data, token) {

  let formData = new FormData();
  formData.append('type', data.type);
  formData.append('photo', data.src);

  const url = `${config.apiUrl}/installs/${installSid}/exams/${examSid}/sessions/${sessionSid}/photos`;

  const options = {
    method: "PUT",
    body: formData,
    headers: {
      token: token
    }
  };

  let response = await fetch(url, options);
  return response;
}