import React, {useEffect, useImperativeHandle, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {pTranslationArray, getOperatingSystem} from "../../helpers/Helpers";
import * as icons from "../../icons/Icons";
import TwinPane from "../TwinPane";
import {config} from "../../config";
import macDownload from "../../img/utility_app_download_mac.png";
import macSecurity from '../../img/mac_allow_utility_app.png'
import macIcon from '../../img/utility_app_icon_mac.png'
import windowsProgress from '../../img/windows_progress.png'
import windowsDownload from '../../img/windows_download.png'
import './index.css';
import {
    closeBlockedApplications,
    getUtilityAppVersion,
    getUtilityAppMinimumVersion
} from "../Stepper/api";
import {ActionTypes} from "@smarterservices/twilio-sync-client";

function UtilityAppCheck(props, ref) {

    const { t: translate } = useTranslation();
    const translation = translate('utility_app_check');
    const { setNextEnabled, syncClient, setLoading, loading} = props;
    const [appRunning, setAppRunning] = useState(false);
    const [blockedApplications, setBlockedApplications] = useState([]);
    const [firstCheck, setFirstCheck] = useState(true);
    const actionTypes = new ActionTypes();
    const OS = getOperatingSystem(window);

    useImperativeHandle(ref, () => {
      return {
        emitterCallback: (syncDoc) => emitterCallback(syncDoc)
      }
    })

    /*
     * Version Compare - This is a function that was taken from Stack Overflow to check if versions against one another
     * @param v1 - version like "17.0.1"
     * @param v2 - version like "16.2.13"
     * @param options
     * @returns {number} - Returns 0 if equal, -1 if v1 < v2 and 1 if v1 > v2
     */
    function versionCompare(v1, v2, options) {
        let lexicographical = options && options.lexicographical,
            zeroExtend = options && options.zeroExtend,
            v1parts = v1.split('.'),
            v2parts = v2.split('.');

        function isValidPart(x) {
            return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
        }

        if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
            return NaN;
        }

        if (zeroExtend) {
            while (v1parts.length < v2parts.length) v1parts.push('0');
            while (v2parts.length < v1parts.length) v2parts.push('0');
        }

        if (!lexicographical) {
            v1parts = v1parts.map(Number);
            v2parts = v2parts.map(Number);
        }

        for (let i = 0; i < v1parts.length; ++i) {
            if (v2parts.length === i) {
                return 1;
            }

            if (v1parts[i] === v2parts[i]) {
                continue;
            } else if (v1parts[i] > v2parts[i]) {
                return 1;
            } else {
                return -1;
            }
        }

        if (v1parts.length !== v2parts.length) {
            return -1;
        }

        return 0;
    }

    useEffect(() => {
      if(!syncClient){
        setTimeout(() => {
          syncClient.setSync({ currentStep: "UtilityAppCheck" })
        },3000)
      }

      const getSyncDoc = async () => {
        if (syncClient) {
          const syncDoc = await syncClient.getSync();
          updateBlockedApplications(syncDoc);
        }
      }

      const checkUtilityApp = async () => {
        try {
          // Check to see if the utility is running, and if it is the minimum version required
          const current = await getUtilityAppVersion();
          const minimum = await getUtilityAppMinimumVersion();

          // TODO: stop a student if the version is not up to date
          if (versionCompare(current.version, minimum.version) < 0) {
              console.log('Utility App is outdated');
          } else {
              console.log('Utility App is up to date');
          }
          syncClient.addAction(actionTypes.session.startUtilityApp());
          if(firstCheck){
            await getSyncDoc()
            setFirstCheck(false);
          }
          setAppRunning(true);
          clearInterval(interval);
        } catch (e) {
          console.log(e)
        }
      }

      const interval = setInterval(async () => {
        await checkUtilityApp()
      }, 5000);

      //eslint-disable-next-line
    },[]);

    useEffect(() => {
      const getSyncDoc = async () => {
        if (syncClient) {
          const syncDoc = await syncClient.getSync();
          updateBlockedApplications(syncDoc);
        }
      }

      if(appRunning && blockedApplications?.length === 0){
        try {
            syncClient.addAction(actionTypes.session.startKillingBlockedApps());
        } catch (error) {
            // eat error
        }
        setNextEnabled(true);
      }

      if(appRunning && blockedApplications?.length > 0){
        setTimeout(async () => {
           await getSyncDoc();
        }, 5000)
      }
    })


  const emitterCallback = (syncDoc) => {
    if(appRunning && syncDoc.blockedApplications){
      updateBlockedApplications(syncDoc)
    }
  }

  const updateBlockedApplications = (syncDoc) => {
    let applications = [];
    for (const app of syncDoc.blockedApplications.applications){
      if(applications.indexOf(app.applicationName) === -1){
        applications.push(app.applicationName)
      }
    }
    setBlockedApplications(applications);
  }


  const listItems = blockedApplications.map((application) =>
      <li>{application}</li>
  );


    const renderLeftPane = () => {
        if (!appRunning && OS === 'MacOS') {
            return (
                <div>
                    {pTranslationArray(translation.left_pane.app_not_running.content)}
                    <h2>{translation.left_pane.steps[0]}</h2>
                    {pTranslationArray(translation.left_pane.app_not_running.step_1)}
                    <h2>{translation.left_pane.steps[1]}</h2>
                    {pTranslationArray(translation.left_pane.app_not_running.step_2)}
                    <img
                        className='mac-download'
                        src = {macDownload}
                        alt = 'Mac Download'
                    />
                    <h2>{translation.left_pane.steps[2]}</h2>
                    {pTranslationArray(translation.left_pane.app_not_running.step_3_mac)}

                    <div className='flex-row'>
                      <img
                          className='mac-icon'
                          src = {macIcon}
                          alt = 'Mac Icon'
                      />
                      <img
                          className='mac-security'
                          src = {macSecurity}
                          alt = 'Mac Security Pop Up'
                      />
                    </div>
                </div>
            );
        }
        else if (!appRunning && OS !== 'MacOS') {
            return (
                <div>
                    {pTranslationArray(translation.left_pane.app_not_running.content)}
                    <h2>{translation.left_pane.steps[0]}</h2>
                    {pTranslationArray(translation.left_pane.app_not_running.step_1)}
                    <h2>{translation.left_pane.steps[1]}</h2>
                    {pTranslationArray(translation.left_pane.app_not_running.step_2)}
                    <img
                        className='mac-download'
                        src = {windowsDownload}
                        alt = 'Windows pinned download'
                    />
                    <h2>{translation.left_pane.steps[2]}</h2>
                    {pTranslationArray(translation.left_pane.app_not_running.step_3_windows)}
                    <img
                        src = {windowsProgress}
                        alt = 'Windows download progress'
                    />
                </div>
            );
        } else if(appRunning && blockedApplications?.length !== 0) {
            return (
                <div>
                  {pTranslationArray(translation.left_pane.blocked_apps_running.content)}
                  <h2>{translation.left_pane.steps[0]}</h2>
                  {pTranslationArray(translation.left_pane.blocked_apps_running.step_1)}
                  <h2>{translation.left_pane.steps[1]}</h2>
                  {pTranslationArray(translation.left_pane.blocked_apps_running.step_2)}
                </div>
            );
        } else if(appRunning && blockedApplications?.length === 0){
          return(
              <div>
                {pTranslationArray(translation.left_pane.success.content)}
              </div>
              )

        }

    };

    const renderRightPane = () => {
        if (!appRunning && OS === "MacOS") {
            return (
                <div className="flex-container">
                    <button
                        className="btn"
                        onClick={()=> window.open(config.desktopAppInfo.mac.url, '_blank')}
                    >
                        {translation.right_pane.app_not_running.button}
                    </button>
                </div>
            )
        } else if (!appRunning && OS !== "MacOS") {
        return (
            <div className="flex-container">
              <button
                  className="btn"
                  onClick={()=> window.open(config.desktopAppInfo.windows.url, '_blank')}
              >
                {translation.right_pane.app_not_running.button}
              </button>
            </div>
        )
        } else if(appRunning && blockedApplications?.length !== 0){
          return(
              <div className='flex-container'>
                <ul className='apps-list'>{listItems}</ul>
                <button
                    className="btn"
                    onClick={() =>  {

                      setLoading([...loading, 'closeApps']);
                      closeBlockedApplications().then(response => {
                        setLoading(loading.filter(item => item !== 'closeApps'));
                      })
                    }}
                >
                  {translation.right_pane.blocked_apps_running.button}
                </button>
              </div>
          )
        } else {
            return (
                <div className="flex-div">
                  <icons.iconCheckCircle />
                  <h2>{translation.left_pane.success.header}</h2>
                </div>
            )
        }

    };

    return(
        <>
            <TwinPane leftPane={renderLeftPane()} rightPane={renderRightPane()}/>
        </>
    );
} export default React.forwardRef(UtilityAppCheck);