import * as atatus from 'atatus-spa';
import {httpRequest} from "../../helpers/Helpers";

export async function getWaitTime(installSid, examSid, sessionSid, token) {
  const url = `/installs/${installSid}/exams/${examSid}/sessions/${sessionSid}/waitTime`;

  const requester = new httpRequest();

  try {
    const response = await requester.get(url, { token });
    return response.data;
  } catch (error) {
    atatus.notify(
      new Error('Error occurred retrieving review wait time'),
      {
        errorName: error.name,
        errorMessage: error.message,
        installSid,
        examSid,
        sessionSid
      }
    )
    console.error('Error fetching wait time', { error });
    return false;
  }
}