import { useTranslation } from 'react-i18next';
import React, {useEffect, useImperativeHandle, useState} from "react";
import './index.css'
import TwinPane from "../TwinPane";
import * as icons from "../Icons";
import screenShareGif from '../../img/screen-sharing.gif'
import { ActionTypes } from '@smarterservices/twilio-sync-client';

 function ScreenSharing(props, ref) {
    const actionTypes = new ActionTypes();
    const { setLoading, syncClient, throwError, loading, resolutionBand } = props;
    const { t: translation } = useTranslation();
    const [sharing, setSharing] = useState(false);


   useEffect( () => {
     const {setNextEnabled} = props;
     setNextEnabled(sharing);
   }, [props, sharing])



   const emitterCallback = actionObj => {
     if (actionObj.action === 'userSharedScreen') {
       if (actionObj.sharedScreen) {
         setSharing(true);
       }
       setLoading(loading.filter(item => item !== 'shareScreen'))
     }
     if (actionObj.action === 'passErrorToOnboarding') {
       throwError(actionObj.errorMessage);
     }
   }

   useImperativeHandle(ref, () => {
     return {
       emitterCallback: (actionObj) => emitterCallback(actionObj)
     }
   })

  const shareScreen = async () => {
      const syncDoc = await syncClient.getSync();
      syncClient.addAction(actionTypes.session.prepareAndStartRecording(syncDoc.audioDeviceId, syncDoc.videoDeviceId, resolutionBand));
      setLoading([...loading, 'shareScreen']);
  }

    const renderLeftPane = () => {
        return(
            <div className="body">
                <p>{translation('screen_sharing.left_pane.content.0')}</p>

                <img
                    className='screen_share_example'
                    src = {screenShareGif}
                    alt = 'screen share example'
                />

                <h2>{translation('screen_sharing.left_pane.steps.0')}</h2>
                <p>{translation('screen_sharing.left_pane.step_1.0')}</p>

                <h2>{translation('screen_sharing.left_pane.steps.1')}</h2>
                <p>{translation('screen_sharing.left_pane.step_2.0')}</p>

                <h2>{translation('screen_sharing.left_pane.steps.2')}</h2>
                <p>{translation('screen_sharing.left_pane.step_3.0')}</p>
                <p>{translation('screen_sharing.left_pane.step_3.1')}</p>
            </div>
        )


    }
    const renderRightPane = () =>{
        if(sharing){
            return(
                <div className = "column pass">
                    < icons.iconCheckCircle/>
                    <h2> {translation('screen_sharing.right_pane.success')} </h2>
                </div>
            )
        }
        else{
            return(
                <div className = "column">
                    <button
                        className='btn'
                        onClick={shareScreen}
                    >
                        {translation('screen_sharing.right_pane.share_button')}
                    </button>
                    <div className= 'bounds'>

                    </div>
                </div>
            )
        }
    }

    return(
        <>
            <TwinPane
                leftPane = {renderLeftPane()}
                rightPane = {renderRightPane()}
            />
        </>
    );
} export default React.forwardRef(ScreenSharing);