import './index.css';
import { useTranslation } from 'react-i18next';
import SPLogo from "../../img/sp_logo.png";

export default function SessionStatusError(props) {
    const { t: translate } = useTranslation();
    const translation = translate("session_status_error");
    const {
        sessionStatus
    } = props;

    let bodyText = '';
    if (sessionStatus === 'Processing' || sessionStatus === 'Completed' || sessionStatus === 'Closed' || sessionStatus === 'Cancelled') {
        bodyText = translation.closed;
    } else if (sessionStatus === 'In Progress') {
        bodyText = translation.in_progress;
    } else {
        bodyText = translation.malformed;
    }

    return (
        <div className='session-status-error-wrapper'>
            <div className='session-status-error_pane'>
                <img className='session-status-error-logo' src={SPLogo} alt="SmarterProctoring logo"/>
                <p>{bodyText}</p>
            </div>
        </div>
    );
}
