
import TwinPane from "../TwinPane";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as icons from "../Icons";
import {pTranslationArray} from "../../helpers/Helpers";
import {config} from '../../config';
import './index.css';
import macScreenShareGif from "../../img/mac_screen_share_gif.gif";

function MacPermissionCheck(props){

  const { t: translate } = useTranslation();
  const translation = translate('mac_permission_check');
  const [status, setStatus] = useState('notTested');
  const [version, setVersion] = useState('ventura');

  const {setNextEnabled} = props;

  useEffect(() => {

    if(status === 'passed'){
      setNextEnabled(true);
    } else {
      setNextEnabled(false);
    }
    // eslint-disable-next-line
  }, [status]);

  /**
   * * testScreenShare - Prompts user to share screen but with the option tabs, window, and entire screen. We want the user to select the entire screen,
   *  ensures we know what option the user selected and if they declined to share at all.
   * @returns
   */
  const testScreenShare = async () => {
    // Setting a display surface is important so it defaults to the 'Entire Screen" option for the user.
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({video: {displaySurface: 'monitor'}});

      // Get the tracks settings and ensure the user selected the correct option.
      const track = stream.getVideoTracks()[0];
      const trackSettings = track.getSettings();
      const displaySurface = trackSettings.displaySurface;

      if (displaySurface === 'monitor') {
        setStatus('passed');
      } else {
        setStatus('incorrectSource');
      }
      // Immediately stop the tracks as this is just a test to ensure we can get the users screen correctly.
      stream.getTracks().forEach(track => track.stop());
    } catch(e) {
        if(e.message === ('Permission denied by system')){
          setStatus('failed');
        } else {
          setStatus('incorrectSource');
        }
      };
  }


  const renderLeftPane = () => {

    if(status === 'failed') {
      return (
          <div className=''>
            {pTranslationArray(translation.content.failed.step_1)}
            <h2>{translation.content.failed.steps[0]}</h2>
            {pTranslationArray(translation.content.failed.step_2)}
            <h2>{translation.content.failed.steps[1]}</h2>
            {pTranslationArray(translation.content.failed.step_3)}

            <div className='flex-row tabs'>
              <button className='btn tab' disabled={version === 'ventura' ? true : false} onClick={() => {
                setVersion('ventura')
              }}>{translation.content.ventura_button}
              </button>
              <button className='btn tab' disabled={version === 'pre_ventura' ? true : false} onClick={() => {
                setVersion('pre_ventura')
              }}>{translation.content.pre_ventura_button}
              </button>
            </div>
            <iframe src={config.mac_permissions_pdfs[version]} width="100%" height="700px" title="PDF Viewer"></iframe>
          </div>
      );
    } else if(status === 'passed') {
        return(
        <div className='flex-container'>
          {pTranslationArray(translation.content.passed)}
        </div>
      );
    } else if(status === 'incorrectSource') {
      return(
          <div>
            {pTranslationArray(translation.content.incorrect_source)}
            <h2>{translation.content.not_tested.steps[0]}</h2>
            {pTranslationArray(translation.content.not_tested.step_1)}
            <h2>{translation.content.not_tested.steps[1]}</h2>
            {pTranslationArray(translation.content.not_tested.step_2)}
            <h2>{translation.content.not_tested.steps[2]}</h2>
            {pTranslationArray(translation.content.not_tested.step_3)}
            <img
                className='screen_share_example'
                src = {macScreenShareGif}
                alt = 'screen share example'
            />
          </div>
      );
    } else {
      return(
          <div>
            {pTranslationArray(translation.content.not_tested_main)}
            <h2>{translation.content.not_tested.steps[0]}</h2>
            {pTranslationArray(translation.content.not_tested.step_1)}
            <h2>{translation.content.not_tested.steps[1]}</h2>
            {pTranslationArray(translation.content.not_tested.step_2)}
            <h2>{translation.content.not_tested.steps[2]}</h2>
            {pTranslationArray(translation.content.not_tested.step_3)}
            <img
                className='screen_share_example'
                src = {macScreenShareGif}
                alt = 'screen share example'
            />
          </div>
      );
    }
  }

  const renderRightPane = () => {

    if(status === 'failed'){
      return (
          <div className='column incorrect'>
            <icons.iconExclamationCircle class = 'incorrectSourceExclamation'/>
            <h2>{translation.content.failed_text}</h2>
          </div>
      );
    } else if(status === 'passed'){
      return (
          <div className='column pass'>
            <icons.iconCheckCircle />
            <h2>{translation.content.passed_text}</h2>
          </div>
      );
    } else if(status === 'incorrectSource') {
      return(
        <div className='column incorrect'>
          <icons.iconExclamationCircle class = 'incorrectSourceExclamation'/>
          <h2>{translation.content.failed_text}</h2>
          <button className='btn share' onClick={testScreenShare}>{translation.content.screen_share_button}</button>
        </div>
      );
    } else {
      return(
          <div className='column'>
            <button className='btn share' onClick={testScreenShare}>{translation.content.screen_share_button}</button>
          </div>
      );
    }
  }

  return(
      <>
        <TwinPane leftPane={renderLeftPane()} rightPane={renderRightPane()} />
      </>
  );
}

export default MacPermissionCheck