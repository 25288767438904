import './index.css';
import { useTranslation } from 'react-i18next';
import { config } from '../../config'
import SPLogo from "../../img/sp_logo.png";

export default function ExtensionError(props) {
    const { t: translate } = useTranslation();
    const translation = translate("extension_error");
    const {
        buttonLogic,
        extensionErrorValue
    } = props;

    const bodyText = extensionErrorValue === config.extErrorMap.SAME_SESSION_ACTIVE ?
        translation.same_session_active :
        translation.other_session_active;

    return (
        <div className='extension-error-wrapper'>
            <div className='extension-error_pane'>
                <img className='extension-error-logo' src={SPLogo} alt="SmarterProctoring logo"/>
                <p>{bodyText}</p>
                <button
                    className='btn btn--negative'
                    id='extension-error'
                    aria-describedby='error-desc'
                    onClick={buttonLogic}
                >
                    {translation.restart}
                </button>
            </div>
        </div>
    );
}
