import React from 'react';
import PhotoVerification from "../PhotoVerification/index";

export default function FaceVerification(props) {
  const { syncClient, setNextEnabled, throwError } = props;

  return (
    <PhotoVerification
      throwError = {throwError}
      translationComponent={"face_verification"}
      type={"headshot"}
      setNextEnabled={setNextEnabled}
      syncClient={syncClient}
      />
  );
}