import { useTranslation } from 'react-i18next';
import TwinPane from '../TwinPane';
import * as icons from '../../icons/Icons';
import { pTranslationArray } from '../../helpers/Helpers';
import React, {useEffect, useImperativeHandle, useState} from 'react';
import {ActionTypes} from "@smarterservices/twilio-sync-client";

function DisplayCheck(props, ref) {
    const {t: translate} = useTranslation();
    const translation = translate('display_check');
    const {setNextEnabled, syncClient} = props;
    const actionTypes = new ActionTypes();
    const [oneDisplay, setOneDisplay] = useState(false);


    useImperativeHandle(ref, () => {
        return {
            emitterCallback: (actionObj) => emitterCallback(actionObj)
        }
    })

    useEffect(() => {
        syncClient.setSync({ currentStep: "DisplayCheck" })

        //inside use effect for the purpose of interval
        const getDisplayCount = () => {
            syncClient.addAction(actionTypes.extension.getDisplayCount('onboarding'));
        }

        const interval = setInterval(() => {
            getDisplayCount();
        }, 1000);

        setInterval(interval);
        return () => {
            clearInterval(interval);
        }
        //eslint-disable-next-line
    }, []);

    const emitterCallback = actionObj => {

            if (actionObj?.response?.displayCount === 1) {
                setOneDisplay(true);
                setNextEnabled(true);
            } else {
                setOneDisplay(false);
                setNextEnabled(false);
            }
    }

    const renderLeftPane = () => {
        if (oneDisplay) {
            return (
                <div>
                    {pTranslationArray(translation.left_pane.one_display_detected_content)}
                </div>
            );
        } else {
            return (
                <div>
                    {pTranslationArray(translation.left_pane.content)}
                </div>
            );
        }

    };

    const renderRightPane = () => {
        if (oneDisplay) {
            return (
                <div className="flex-div">
                    <icons.iconCheckCircle/>
                    <h2 className="center-text">{translation.right_pane.passed}</h2>
                </div>
            )
        } else {
            return (
                <div className="flex-container">
                    <icons.iconDesktop/>
                </div>
            )
        }
    };

    return (
        <>
            <TwinPane leftPane={renderLeftPane()} rightPane={renderRightPane()}/>
        </>
    );
}
export  default  React.forwardRef(DisplayCheck)