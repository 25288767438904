import './index.css';
import { useState, useEffect } from 'react';
import TwinPane from '../TwinPane';

import { useTranslation } from 'react-i18next';
import {
    pTranslationArray,
    liTranslationArray,
    getOperatingSystem,
    checkExtension,
    pinTabs
} from '../../helpers/Helpers';
import { config } from '../../config';
// Images
import * as icons from '../../icons/Icons';
import ssLogo from '../../img/logo.jpg';
import chromeLogo from '../../img/chrome-logo.png';

export default function ExtensionCheck(props) {
  const { t: translate } = useTranslation();
  const translation = translate('extension_check');
  const { chromeBrowser, setNextEnabled, params } = props;
  const [status, setStatus] = useState('notInstalled');

    useEffect(() => {
        const os = getOperatingSystem(window)

        if(os === 'mobile'){
            setStatus('mobile');
            return
        }

        const sendMessageToExtension = () => {
            checkExtension().then((isValid) => {
                if (isValid) {
                    pinTabs(params, params.systemCheck).then(() => {
                        setStatus('installed');
                        setNextEnabled(true);
                    });
                } else {
                    setStatus('notInstalled');
                    setNextEnabled(false);
                    setTimeout(sendMessageToExtension, 500);
                }
            });
        };

        if (!chromeBrowser) {
          setStatus('notChrome')
        }

        if (status !== 'installed' && chromeBrowser) {
          sendMessageToExtension();
        }
        //eslint-disable-next-line
    }, [setNextEnabled]);

  const renderLeftPane = () => {
    if (status === 'notInstalled') {
      return (
        <div>
          <h2>{translate('general.steps.0')}</h2>
          {pTranslationArray(translation.left_pane.extension_not_installed.step_1)}

          <h2>{translate('general.steps.1')}</h2>
          {pTranslationArray(translation.left_pane.extension_not_installed.step_2)}
        </div>
      )
    } else if (status === 'installed') {
      return (
        <div>
          {pTranslationArray(translation.left_pane.extension_installed.content)}
        </div>
      )
    } else if (status === 'notChrome') {
      return (
        <div>
          {pTranslationArray(translation.left_pane.non_chrome_browser.content)}
        </div>
      )
    } else if (status === 'mobile') {
      return (
        <div>
          {pTranslationArray(translation.left_pane.wrong_device.content)}
          <ul>
            {liTranslationArray(translation.left_pane.wrong_device.bullet_points)}
          </ul>
        </div>
      )
    }
  }

  const renderRightPane = () => {
    if (status === 'installed') {
      return (
        <div className='column'>
          <icons.iconCheckCircle />
          <h2 className="center-text">{translation.install_successful}</h2>
        </div>
      )
    } else if(status === 'notInstalled') {
      return (
        <div className="flex-container">
          <img
            className="img"
            src={ssLogo}
            alt="SmarterServices logo"
          />
          <button
            className='btn'
            onClick={()=> window.open(config.extensionDownloadLink, '_blank')}
          >
              {translation.buttons.install_extension}
          </button>
        </div>
      )
    } else if(status === 'notChrome'){
      return (
        <div className="flex-container">
          <img
            className="img"
            src={chromeLogo}
            alt="Google Chrome logo"
          />
          <button
            className="btn"
            onClick={()=> window.open(config.chromeDownloadLink, '_blank')}
          >
            {translation.buttons.download_chrome}
          </button>
        </div>
      )
    } else if (status === 'mobile') {
      return (
        <div className='flex-container'>
          <icons.iconDesktop />
        </div>
      )
    }
  }

  return (
      <TwinPane leftPane={renderLeftPane()} rightPane={renderRightPane()} />
  );
}