import { useTranslation } from 'react-i18next';
import TwinPane from '../TwinPane';
import * as icons from '../Icons';
import { liTranslationArray, getOperatingSystem } from '../../helpers/Helpers';
import './index.css';

function ComputerCheck(props) {
  const { t: translate } = useTranslation();
  const translation = translate('computer_check');


  const renderLeftPane = () => {
    return (
      <article>
        <p>{translation.left_pane.header}</p>
        <ul>
          {liTranslationArray(translation.left_pane.list)}
        </ul>
      </article>
    )
  };

  const renderRightPane = () => {
    const { setNextEnabled } = props;
    const os = getOperatingSystem(window);

    if (os === 'MacOS' || os === 'Windows OS') {
      setNextEnabled(true);
      return (
        <div className="flex-div">
          <icons.iconCheckCircle/>
          <h2 className="center-text">{translation.right_pane.passed}</h2>
        </div>
      );
    } else if (os === 'CrOS') {
      return (
        <div className='flex-div'>
          <icons.iconTimesCircle />
          <h2 className="center-text">{translation.right_pane.failed_chrome}</h2>
        </div>
      )
    } else {
      return (
        <div className='flex-div'>
          <icons.iconTimesCircle />
          <h2 className="center-text">{translation.right_pane.failed}</h2>
        </div>
      )
    }
  };

  return (
    <TwinPane leftPane={renderLeftPane()} rightPane={renderRightPane()} />
  )
}

export default ComputerCheck;