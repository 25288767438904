import { useTranslation } from "react-i18next";
import React, { useEffect, useState, useRef } from 'react';
import Compressor from "compressorjs";
import "./index.css";
import { pTranslationArray } from "../../helpers/Helpers";
import { uploadSessionPhoto } from './api.js';
import { ActionTypes, AnnotationTypes } from "@smarterservices/twilio-sync-client";
import TwinPane from "../TwinPane/index";
import HeadFrame from './Onboarding_Oval.png';
import IDIcon from './ID_Icon.png';
import IDFrame from './ID_Frame.png';
import PersonIcon from './Person_Icon.png';

export default function PhotoVerification(props) {
  const { t: translate } = useTranslation();
  const { syncClient, setNextEnabled, translationComponent, throwError, type } = props;
  const translation = translate(translationComponent);
  const actionTypes = new ActionTypes();

  // Set State
  const [headshot, setHeadshot] = useState(false);
  const photoRef = useRef(null);
  const videoRef = useRef(null);
  const [appInstall, setAppInstall] = useState();
  const [examSid, setExamSid] = useState();
  const [sessionSid, setSessionSid] = useState();

  /**
   * raisePhotoAnnotation - Creates an annotation after a photo is taken
   */
  const raisePhotoAnnotation = (path) => {
    if(type === 'headshot') {
      syncClient.addAction(actionTypes.session.createAnnotation(AnnotationTypes.facePhotoTaken(path)));
    } else {
      syncClient.addAction(actionTypes.session.createAnnotation(AnnotationTypes.idPhotoTaken(path)));
    }
  }

  useEffect(() => {

    const startWebcamStream = (syncDoc) => {
      let videoDeviceObj = {};
      try {
        videoDeviceObj = JSON.parse(syncDoc.videoDeviceId);
      } catch (error) {
        videoDeviceObj.deviceId = '';
        console.error('Error parsing JSON:', error);
      }
      navigator.mediaDevices.getUserMedia({ video: { deviceId: videoDeviceObj?.deviceId } })
      .then(stream => {
        const video = document.getElementById('video');
        video.srcObject = stream;
        video.onloadedmetadata = () => {
          video.play();
        }
      })
    }

    const getSyncDoc = async () => {
      if (syncClient) {
        const syncDoc = await syncClient.getSync();
        setAppInstall(syncDoc.sessionStart.session.installSid);
        setExamSid(syncDoc.sessionStart.exam.sid)
        setSessionSid(syncDoc.sessionStart.session.sid);
        startWebcamStream(syncDoc);
      }
    }
    getSyncDoc();

  },[syncClient])

  useEffect(() => {
    syncClient.setSync({currentStep: "PhotoVerification"});
    //eslint-disable-next-line
  },[])
  /**
   * convert dataurl to file
   * @ param {string} dataurl - dataurl address
   * @ param {string} file name - file name
   */
  // Create Blob file from URL
  const dataURLtoFile = (dataUrl, fileName) => {
    var arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, {type:mime});
  }

  const capturePhoto = () => {
    const { type } = props;
    const video = videoRef.current;
    const canvas = photoRef.current;
    const ctx = canvas.getContext('2d');
    // Set the canvas width and height to match the video element
    canvas.height = video.clientHeight;
    canvas.width = video.clientWidth;
    // Draw the image of the video element onto the canvas
    ctx.drawImage(video, 0 , 0 , canvas.width , canvas.height);
    //Set state
    setHeadshot(true);
    // Convert the photo to an image
    const photo = new Image();
    photo.src = canvas.toDataURL('image/jpg');

    // Convert image base64 to file for compression
    let photoFile = dataURLtoFile(canvas.toDataURL('image/jpg', 'student_ID_verification'));
    // New copy of photo to compress
    const compressedPhoto = new Image();
    compressedPhoto.type = type;

    // Query the url and then search for the token being used
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');

    // Compress Image for faster uploading
    new Compressor(photoFile, {
      quality: 0.6,
      maxWidth: 246,
      maxHeight: 328,
      // What type to convert file to
      convertTypes: 'image/jpeg',
      // Byte threshold to convert to jpeg (5kb)
      convertSize: 5000,

      success(result) {
        let blob = result
        var reader = new FileReader();

        reader.readAsDataURL(blob);
        reader.onloadend = function () {
        var base64String = reader.result;

        compressedPhoto.src = base64String;

        uploadSessionPhoto( appInstall, examSid, sessionSid, compressedPhoto, token)
            .then(response => {
              // Allow user to pass if status is 200
              if (response.status === 200) {
                response.json()
                    .then(json => {
                      const path = json.sessionPhotos?.original?.path;
                      raisePhotoAnnotation(path);
                    })
                setNextEnabled(true);
              }
            })
            .catch(error => {
              throwError(translation.upload_error);
              setNextEnabled(false);
            });
          }
      }
    });

  }

  const retakePhoto = () => {
    setNextEnabled(false);
    setHeadshot(false);
  }

  const personIcon = () => {
    return (
      <img className="headshot-icon" src={PersonIcon} alt="Person Icon"/>
    )
  }

  const idIcon = () => {
    return (
        <img className="id-icon" src={IDIcon} alt="Id icon"/>
    )
  }

  const idFrame = () => {
    return (
      <img className="webcam__frame--landscape" src={IDFrame} alt="ID frame" />
    )
  }

  const renderLeftPane = () => {
    return (
        <article>
          {/* Shows the Left panel instructions */}
              <>
                <h2>{translation.step_one}</h2>
                {pTranslationArray(translation.step_1)}

                <h2>{translation.step_two}</h2>
                {pTranslationArray(translation.step_2)}
              </>
        </article>
    )
  };

  const renderRightPane = () => {
    return (
        <article className="id-right-pane">
            {type === 'headshot' ? personIcon() : idIcon()}
            <figure className="webcam">
              <video id="video" className={!headshot ? 'face-check-video' : 'hide-me'} ref={videoRef}/>
              <canvas id="canvas" className={!headshot ? 'hide-me' : 'face-check-canvas'} ref={photoRef}/>
              {type === 'headshot' && !headshot &&
                <img src={HeadFrame} className="webcam__frame" alt=""/>
              }
              {type === 'idFront' && !headshot &&
                 idFrame()
              }
                <div className="action--tray">
                  <button className="btn capture-photo" onClick={!headshot ? capturePhoto : retakePhoto}>{!headshot ? translation.capture_photo : translation.retake_photo}</button>
                </div>
            </figure>
        </article>
    )
  };

  return (
    <>
      <TwinPane leftPane={renderLeftPane()} rightPane={renderRightPane()} />
    </>
  );
}