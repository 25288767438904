import { useEffect } from "react";
import { config } from "../../config";

export default function Salesforce(props) {
  useEffect(()=> {
    const { params, token, decodedToken, examSid } = props;
    const { installSid, sessionSid } = params;

    const optionsDefined = !!window.inAppHelpOptions;

    if (!optionsDefined) {
      window.inAppHelpOptions = {
        token: token,
        application_sid: 'AP6800b9e2e1794dbbbf6ad866194821d3',
        context: 'session_onboarding',
        sids: {
          course_sid: decodedToken.courseSid,
          enrollment_sid: decodedToken.enrollmentSid,
          install_sid: installSid,
          exam_sid: examSid,
          session_sid: sessionSid,
          user_sid: decodedToken.userSid
        }
      }

      const script = document.createElement('script');
      script.src = config.supportScriptUrl;
      script.type = 'text/javascript';
      script.onload = () => window.initHelpCenter();

      document.body.appendChild(script);
    }
  }, [props]);
}