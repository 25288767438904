import './index.css';
import { useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import testSound from '../../Sounds/audioSample.mp3'

import TwinPane from '../TwinPane';
import Microphone from '../Microphone';
import { pTranslationArray } from '../../helpers/Helpers';

// Images
import builtInSpeakersImg from '../../img/built_in_speakers.png';
import externalSpeakersImg from '../../img/external_speakers.png';
import noEarbudsImg from '../../img/no_earbuds.png';
import noHeadphonesImg from '../../img/no_headphones.png';

let focusIndex = 0;

export default function AudioCheck(props) {
  const { t: translate } = useTranslation();
  const {setNextEnabled} = props;
  const [selectedInput, setSelectedInput] = useState(JSON.stringify({deviceId: 'default', name: null}));
  const [soundModal, setSoundModal] = useState(false);
  const [audioOutputTested, setAudioOutputTested] = useState(false);
  const [microphoneTested, setMicrophoneTested] = useState(false);
  const [audioHelpModal, setAudioHelpModal] = useState(false);
  const [inputs, setInputs] = useState([]);
  const lastFocused = useRef(null);

  const {syncClient} = props;

  const translation = translate('audio_check');

  // The two functions below are very similar but they need to be separate
  // so we don't run into issues when trying to add and remove eventListeners
  /**
   * handleSoundFocus - handles focus inside of the first sound modal
   * @param {event} event - Keydown event
   */
  const handleSoundFocus = useCallback((event)=> {
    const elements = [
      document.getElementById('modal-description'),
      document.getElementById('modal-text'),
      document.getElementById('audio-no'),
      document.getElementById('audio-yes')
    ]

    if (event.which === 9 && event.shiftKey) {
      event.preventDefault();
      if (focusIndex === 0) {
        focusIndex = elements.length - 1;
        elements[focusIndex].focus()
      } else {
        focusIndex--;
        elements[focusIndex].focus()
      }
    } else if (event.which === 9) {
      event.preventDefault();
      if (elements.length === (focusIndex + 1)) {
        focusIndex = 0;
        elements[focusIndex].focus();
      } else {
        focusIndex++;
        elements[focusIndex].focus();
      }
    }
  }, [])

  /**
   * handleHelpFocus - handles focus inside of the second help modal
   * @param {event} event - Keydown event
   */
  const handleHelpFocus = useCallback((event)=> {
    const elements = [
      document.getElementById('modal-description'),
      document.getElementById('modal-text'),
      document.getElementById('audio-close'),
      document.getElementById('audio-replay')
    ]

    if (event.which === 9 && event.shiftKey) {
      event.preventDefault();
      if (focusIndex === 0) {
        focusIndex = elements.length - 1;
        elements[focusIndex].focus()
      } else {
        focusIndex--;
        elements[focusIndex].focus()
      }
    } else if (event.which === 9) {
      event.preventDefault();
      if (elements.length === (focusIndex + 1)) {
        elements[0].focus();
        focusIndex = 0;
      } else {
        focusIndex++;
        elements[focusIndex].focus();
      }
    }
  }, [])


  useEffect(() => {
      getAudioDevices();
  },[])

  useEffect(() => {
    focusIndex = 0;

    if (audioHelpModal || soundModal) {
      document.getElementById('modal-description').focus();
    }

    if (audioHelpModal) {
      document.addEventListener('keydown', handleHelpFocus)
    } else {
      document.removeEventListener('keydown', handleHelpFocus);
    }

    if (soundModal) {
      document.addEventListener('keydown', handleSoundFocus)
    } else {
      document.removeEventListener('keydown', handleSoundFocus);
    }

    if (!audioHelpModal && !soundModal) {
      if (lastFocused.current) {
        lastFocused.current.focus()
        lastFocused.current = null;
      }

    }

  }, [audioHelpModal, soundModal, handleHelpFocus, handleSoundFocus])

  useEffect(() => {
      if(audioOutputTested && microphoneTested){
          syncClient.setSync({audioDeviceId: selectedInput})
              .then(success => {
                  setNextEnabled(true);
              })
      }
      else{
          setNextEnabled(false)
      }
  },[microphoneTested, audioOutputTested, setNextEnabled, selectedInput, syncClient])

  const getAudioDevices = () => {
      navigator.mediaDevices
          .enumerateDevices()
          .then(devices => {
              const microphones = [];
              devices.forEach(device => {
                  if (device.kind === 'audioinput') {
                      microphones.push(device);
                  }
              });
              setInputs(microphones);
          });
  }

  const selectInput = (e) =>{
      setSelectedInput(e.target.value)
      setMicrophoneTested(false);

      const syncObject = {
          audioDeviceId: null,
      }
      syncClient.setSync(syncObject)
          .then(success => {
              console.log('Cleared')
          })
          .catch(error => {
              //TODO: Add error function in an application store to show users what went wrong
              console.log(error)
          })
  }

  const renderMicOptions = () => {
      return inputs.map((input, i) => {
        return <option value={JSON.stringify({deviceId: input.deviceId, name: input.label})} key={`microphone-${i}`}>{input.label}</option>
      });
  }

  const playSound = () => {
    lastFocused.current = document.activeElement;
    setSoundModal(true);
    const audio = new Audio(testSound);
    audio.play();
  }

    const renderLeftPane = () => {
        return (
          <div>
            <h2>{translate('general.steps.0')}</h2>
            {pTranslationArray(translation.left_pane.step_1)}

            <h2>{translate('general.steps.1')}</h2>
            {pTranslationArray(translation.left_pane.step_2)}

            <h2>{translate('general.steps.2')}</h2>
            {pTranslationArray(translation.left_pane.step_3)}
          </div>
        )
    }

  const renderRightPane = () => {
    return (
      <div className='flex-container__audio-check'>
        <table className='img-banner'>
          <tbody>
            <tr>
              <td><img src={noEarbudsImg} className='img-small' alt='Earbuds with a red X'/></td>
              <td><img src={noHeadphonesImg} className='img-small' alt='Headphones with a red X'/></td>
              <td><img src={builtInSpeakersImg} className='img-small img-small--adjusted' alt='Built-in speakers with a green checkmark'/></td>
              <td><img src={externalSpeakersImg} className='img-small img-small--adjusted' alt='External speakers with a green checkmark'/></td>
            </tr>
            <tr>
              <td><p>{translation.image_banner.earbuds}</p></td>
              <td><p>{translation.image_banner.headphones}</p></td>
              <td><p>{translation.image_banner.built_in}</p></td>
              <td><p>{translation.image_banner.external}</p></td>
            </tr>
          </tbody>
        </table>
        <div className='mic-check'>
          <label className="select__audio-check" htmlFor="mic">
          <select onChange={(e) => {
            selectInput(e)
          }} name='mic' id='mic'>
            {renderMicOptions()}
          </select>
          </label>
            <Microphone
              microphone={selectedInput}
              onAudioDetected={()=>setMicrophoneTested(true)}
            />
        </div>

        <button className='btn' onClick={playSound}>{translation.buttons.play_sound}</button>
      </div>
    )
  }

  return (
    <>
      <TwinPane leftPane={renderLeftPane()} rightPane={renderRightPane()} />

      {soundModal &&
        <div className='modal-container'>
        {/* This overlay should be reworked*/}
          <span className='modal-overlay'></span>
          <div className='modal-content'>
            <strong className='modal-header'>{translation.modal.header}</strong>
            <p className='meta' id='modal-description' tabIndex={-1}>{translate("general.modal_description")}</p>
            <p id='modal-text' tabIndex={-1}>{translation.did_the_sound_play}</p>

            <div className='btn-group'>
              <button
                className='btn btn--outline'
                id='audio-no'
                onClick={() => {
                  setSoundModal(false);
                  setAudioHelpModal(true);
                  setAudioOutputTested(false);
                }}
              >
                {translation.buttons.no}
              </button>
              <button
                className='btn'
                id='audio-yes'
                onClick={() => {
                  setSoundModal(false);
                  setAudioOutputTested(true);
                }}
              >
                {translation.buttons.yes}
              </button>
            </div>

          </div>
        </div>
      }

      {audioHelpModal &&
        <div className='modal-container'>
        {/* This overlay should be reworked*/}
          <span className='modal-overlay'></span>
          <div className='modal-content'>
            <strong className='modal-header'>{translation.modal.header}</strong>
            <p className='meta' id='modal-description' tabIndex={-1}>{translate("general.modal_description")}</p>
            <p id='modal-text' tabIndex={-1}>{translation.check_settings}</p>

            <div className='btn-group'>
              <button
                className='btn btn--outline'
                id='audio-close'
                onClick={() => {
                  setAudioHelpModal(false);
                }}
              >
                {translation.buttons.close}
              </button>
              <button
                className='btn'
                id='audio-replay'
                onClick={() => {
                  setAudioHelpModal(false);
                  playSound();
                }}
              >
                {translation.buttons.replay_sound}
              </button>
            </div>

          </div>
        </div>
      }
    </>
  );
}