import './index.css';
import { useTranslation } from 'react-i18next';

export default function PermittedItems(props) {
  const { t: translate } = useTranslation();
  const translation = translate("permitted_items")
  const {configuration} = props;

  return (
    <div className='permitted-items'>
      <strong>{translation.permitted_items}</strong>
    <ul className='data'>
      {configuration.permittedBathroomBreak &&
        <li className='item'>
          <strong>{translation.bathroom_break}</strong>
          {configuration.permittedBathroomBreakInformation &&
            configuration.permittedBathroomBreakInformation
          }
        </li>
      }
      {configuration.permittedBlankPaper &&
        <li className='item'>
          <strong>{translation.blank_paper}</strong>
          {configuration.permittedBlankPaperInformation &&
            configuration.permittedBlankPaperInformation
          }
        </li>
      }
      {configuration.permittedCalculator &&
        <li className='item'>
          <strong>{translation.calculator}</strong>
          {configuration.permittedCalculatorInformation &&
            configuration.permittedCalculatorInformation
          }
        </li>
      }
      {configuration.permittedDictionary &&
        <li className='item'>
          <strong>{translation.dictionary}</strong>
          {configuration.permittedDictionaryInformation &&
            configuration.permittedDictionaryInformation
          }
        </li>
      }
      {configuration.permittedEarplugs &&
        <li className='item'>
          <strong>{translation.earplugs}</strong>
          {configuration.permittedEarplugsInformation &&
            configuration.permittedEarplugsInformation
          }
        </li>
      }
      {configuration.permittedFormulaSheet &&
        <li className='item'>
          <strong>{translation.formula_sheet}</strong>
          {configuration.permittedFormulaSheetInformation &&
            configuration.permittedFormulaSheetInformation
          }
        </li>
      }
      {configuration.permittedNotes &&
        <li className='item'>
          <strong>{translation.notes}</strong>
          {configuration.permittedNotesInformation &&
            configuration.permittedNotesInformation
          }
        </li>
      }
      {configuration.permittedTextbook &&
        <li className='item'>
          <strong>{translation.textbook}</strong>
          {configuration.permittedTextbookInformation &&
            configuration.permittedTextbookInformation
          }
        </li>
      }
      {configuration.permittedWebsites &&
        <li className='item'>
          <strong>{translation.websites}</strong>
          {configuration.permittedWebsitesInformation &&
            configuration.permittedWebsitesInformation
          }
        </li>
      }
    </ul>
    </div>
  )
}