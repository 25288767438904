// src/SpeedTest.js
import React, {useEffect, useRef, useState} from 'react';
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
import { config } from '../../config';
import './index.css'
import {pTranslationArray} from "../../helpers/Helpers";
import * as icons from "../Icons";
import TwinPane from "../TwinPane";
import {useTranslation} from "react-i18next";
import {ActionTypes} from "@smarterservices/twilio-sync-client";
import AnnotationTypes from "@smarterservices/twilio-sync-client/lib/annotationTypes";

export default function SpeedTest(props) {

  const { syncClient, setResolutionBand, params } = props;
  const { examSid, installSid, token, sessionSid } = params;

  const { t: translate } = useTranslation();
  const translation = translate('internet_speed_check');

  const actionTypes = new ActionTypes();

  const  downloadValue = useRef(0);
  const uploadValue = useRef(0);
  const [adjustedUpload, setAdjustedUpload] = useState(0);
  const [adjustedDownload, setAdjustedDownload] = useState( 0);
  const [ pass, setPass ] = useState(false);
  const [ fail, setFail ] = useState(false);

  const speedTestData = useRef(null);
  const scriptRef = useRef(null);

  const startRtmpHlsServer = () => {
    const options = {
      method: "PUT",
      body: JSON.stringify({}),
      headers: {
        token: token
      }
    };
    const url = `${config.apiUrl}/hybrid/rtmp/installs/${installSid}/exams/${examSid}/sessions/${sessionSid}/start`;

    fetch(url, options).then((response) => {
      console.log('Successfully triggered RTMP HLS Server!');
    }).catch(() => {
      console.error('Error triggering RTMP HLS Server...');
    });
  }


  useEffect( () => {
    const { setNextEnabled } = props;
    setNextEnabled(pass)
  }, [pass, props])

  const options = {
    chart: {
      height: 400,
      type: 'radialBar',
      toolbar: {
        show: false,
      },
      animations:{
        enabled: false
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -120,
        endAngle: 120,
        hollow: {
          margin: 0,
          size: '70%',
          background: '#fff',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front',
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24,
          },
        },
        track: {
          background: '#fff',
          strokeWidth: '67%',
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35,
          },
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: true,
            color: '#888',
            fontSize: '17px',
          },
          value: {
            formatter: function (val) {
              return downloadValue.current;
            },
            color: '#111',
            fontSize: '36px',
            show: true,
          },
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: ['#ABE5A1'],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: 'round',
    },
    labels:['Download Mb/s']
  };

  const uploadRadialOptions = {    chart: {
      height: 480,
      type: 'radialBar',
      toolbar: {
        show: false,
      },
      animations:{
        enabled: false
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -120,
        endAngle: 120,
        hollow: {
          margin: 0,
          size: '70%',
          background: '#fff',
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: 'front',
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24,
          },
        },
        track: {
          background: '#fff',
          strokeWidth: '67%',
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35,
          },
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: true,
            color: '#888',
            fontSize: '17px',
          },
          value: {
            formatter: function (val) {
              return uploadValue.current;
            },
            color: '#111',
            fontSize: '36px',
            show: true,
          },
        },
      },
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: ['#ABE5A1'],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: 'round',
    },
    labels:['Upload Mb/s']
  };

  const initializeSpeedTest = () => {
    const script = document.createElement('script');
    script.src = `${config.speedTestServer}/speedtest.js`;
    script.async = true;
    document.body.appendChild(script);
    scriptRef.current = script;

    script.onload = () => {
      const server =

        {
          name: 'Server 1',
          server: `${config.speedTestServer}`,
          dlURL: 'backend/garbage.php',
          ulURL: 'backend/empty.php',
          pingURL: 'backend/empty.php',
          getIpURL: 'backend/getIP.php',
        }

      // Initialize Speedtest
      const s = new window.Speedtest();
      // parametes can be found here https://github.com/librespeed/speedtest/blob/master/doc.md

      s.setParameter('time_dl_max', 20); // set download test duration to 10 seconds
      s.setParameter('time_ul_max', 20); // set upload test duration to 10 seconds
      s.setParameter('time_auto', false); // set upload test duration to 10 seconds

      s.addTestPoint(server)


      s.onupdate = (data) => {

        const { testState, dlStatus, ulStatus } = data;

        if (testState <= 2 && downloadValue.current !== dlStatus) {

          downloadValue.current = dlStatus;

          if (dlStatus > 99) {
            setAdjustedDownload(99 + Math.random())
          } else if (dlStatus <= 1) {
            setAdjustedDownload(1 + Math.random())
          } else {
            setAdjustedDownload(dlStatus)
          }
        }

        if (testState > 2 && uploadValue.current !== ulStatus && ulStatus > 0) {

          if(data.jitterStatus && data.pingStatus){
            speedTestData.current = (data)
          }

          uploadValue.current = ulStatus;

          if (ulStatus > 99 ) {
            setAdjustedUpload(99 + Math.random())
          } else if (ulStatus <= 1) {
            setAdjustedUpload(1 + Math.random())
          } else {
            setAdjustedUpload((ulStatus))
          }
        }
      };

      s.onend = (aborted) => {
        if(!aborted){
          if(downloadValue.current >= (config.ssDownloadRequirement / 1000) && uploadValue.current >= (config.ssUploadRequirement / 1000) ) {
            setPass(true);

            syncClient.addAction(actionTypes.session.createAnnotation(AnnotationTypes.internetSpeedCheck(true, downloadValue.current, uploadValue.current, speedTestData.current.jitterStatus, speedTestData.current.pingStatus)))

            if (uploadValue.current >= 10) {
              setResolutionBand('High');
            } else if (uploadValue.current >= 5) {
              setResolutionBand('Mid');
            } else {
              setResolutionBand('Low');
            }

          } else {
            setFail(true)
            syncClient.addAction(actionTypes.session.createAnnotation(AnnotationTypes.internetSpeedCheck(false, downloadValue.current, uploadValue.current, speedTestData.jitterStatus, speedTestData.pingStatus)));
          }
        }
      };

        setFail(false);
        uploadValue.current = 0;
        downloadValue.current = 0;
        setAdjustedDownload(0);
        setAdjustedUpload(0);
        s.start();

    }
    return () => {
      // Cleanup the script when the component is unmounted
      document.body.removeChild(script);
    };
  }


  useEffect(() => {
    // Load speedtest.js

    syncClient.setSync({ currentStep: "InternetSpeedCheck" })

    try {
      // only start the hls server when we are not doing a system check...
      if(params && !params.systemCheck){
        startRtmpHlsServer();
      }

    } catch (error) {
      console.error('Error starting RTMP HLS Server', error);
    }

    if (process.env.REACT_APP_ENVIRONMENT === 'sub') {
      setFail(false);
      setPass(true);
      setResolutionBand('High');
    }

    initializeSpeedTest()
    //eslint-disable-next-line
  }, []);


  useEffect(() => {
    // Update chart series
    ApexCharts.exec('radialBar', 'updateSeries', [downloadValue]);
  }, [downloadValue]);




const renderLeftPane = () => {
  if(!fail && !pass) {
    return(
        <div className="body">
          <p>{ translation.left_pane.test_running}</p>
        </div>
    )
  }
  else if(fail){
    return(
        <div className="body">


          <h2>{translation.left_pane.steps[0]}</h2>
          {pTranslationArray(translation.left_pane.minimum_requirements_not_met.step_1)}

          <h2>{translation.left_pane.steps[1]}</h2>
          {pTranslationArray(translation.left_pane.minimum_requirements_not_met.step_2)}

          <h2>{translation.left_pane.steps[2]}</h2>
          {pTranslationArray(translation.left_pane.minimum_requirements_not_met.step_3)}

        </div>
    )
  }
  else if(pass){
    return(
        <div className="body">
          { pTranslationArray(translation.left_pane.minimum_requirements_met.content) }
        </div>
    )
  }
}

const renderRightPane = () => {
  if(!pass){
    return (
        <div className = 'container'>
          <div className='row'>
            {fail &&
                <div className='container'>
                  <icons.iconExclamationCircle class = 'speedCheckError'/>
                  <h2>{translation.right_pane.failed}</h2>
                </div>
            }
          </div>


          <div className='row'>
            <div className='speedTestContainer'>

              <ReactApexChart
                  id='downloadChart'
                  options={options}
                  series={[adjustedDownload]}
                  type="radialBar"
                  height={650}
                  key="radialBar"
              />
            </div>

            <div className='speedTestContainer'>
              <ReactApexChart
                  id='uplaodChart'
                  options={uploadRadialOptions}
                  series={[adjustedUpload]}
                  type="radialBar"
                  height={650}
                  key="radialBar2"
              />
            </div>
          </div>
          <div className='row'>
            {fail &&
              <button className='btn retry' onClick={() => {
                document.body.removeChild(scriptRef.current);
                initializeSpeedTest()
              }}>{translation.right_pane.retry}</button>
            }
          </div>
        </div>
    );
  } else {
    return (
        <div className="pass column">
          <icons.iconCheckCircle/>
          <h2>{translation.right_pane.passed}</h2>
        </div>
    );
  }
}


return(
    <>
      <TwinPane
          leftPane={renderLeftPane()}
          rightPane={renderRightPane()}
      />
    </>
);
}