/*global chrome*/

import {api, config} from "../config";

export function getOperatingSystem(window) {

  let operatingSystem = 'Not known';
  if (window.navigator.appVersion.indexOf('Win') !== -1) {
      operatingSystem = 'Windows OS';
  }
  else if (window.navigator.appVersion.indexOf('Mac') !== -1) {
      operatingSystem = 'MacOS';
  }
  else if (window.navigator.appVersion.indexOf('Chrome') !== -1) {
      operatingSystem = 'CrOS';
  }
  else {
      operatingSystem = 'mobile';
  }

  return operatingSystem;
}

export async function checkExtension() {
    return new Promise((resolve, reject) => {
        try {
            if (chrome.runtime) {
                chrome.runtime.sendMessage(
                    config.spExtensionId,
                    {message: "sp_background__checkExtension"},
                    (response) => {
                        if (response && response.installation === 'SATISFIED') {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    });
            } else {
                resolve(false)
            }
        } catch (error) {
            // no listener is setup on the extension
            resolve(false);
        }
    });
}

export async function pinTabs(params, systemCheck) {
    return new Promise ((resolve, reject) => {
        try {
            if (chrome.runtime) {
                chrome.runtime.sendMessage(config.spExtensionId,
                    {
                        message: "sp_background__pinTabs",
                        sessionSid: params.sessionSid,
                        installSid: params.installSid,
                        examSid: params.examSid,
                        token: params.token,
                        isSystemCheck: systemCheck
                    });
                resolve(true);
            } else {
                resolve(false);
            }
        } catch (error) {
            resolve(false);
        }
    });
}

export function checkBrowser(callback) {
    // The code below needs to be as specific as it is so that we don't allow browsers like
    // Edge or Opera that are chromium based. We only want google chrome users to make
    // it through onboarding
    let isChrome;
    const isChromium = window.chrome;
    const vendorName = navigator.vendor;
    const isOpera = typeof window.opr !== "undefined";
    let isIEedge = false;
    try {
        isIEedge = navigator.userAgentData.brands.some(brand =>{
            return brand.brand === 'Microsoft Edge'
        });
    } catch(error) {
        // No op as this does not exist in some browsers
    }

    if (
        isChromium !== null &&
        typeof isChromium !== "undefined" &&
        vendorName === "Google Inc." &&
        isOpera === false &&
        isIEedge === false
    ) {
        isChrome = true;
    } else {
        isChrome = false;
    }

    if (callback) {
        callback(isChrome);
    }

    return isChrome;
}

/**
 * checkPermissions - Looks at permissions for all 3 urls and returns the permissions that have
 * been enabled for every url. Exam url only needs camera permissions right now.
 * @param {object} permissions - object containing the permission arrays for each url
 * @param {string[]} permissions.onboardingPermissions - enabled permissions from onboarding ui
 * @param {string[]} permissions.sessionPermissions - enabled permissions from session ui
 * @param {string[]} permissions.examPermissions - enabled permissions from exam ui
 * @returns {string[]}
 */
 export function checkPermissions(permissions, containsAllCheck = false) {
  const {onboardingPermissions, sessionPermissions, examPermissions} = permissions;
  const returnArr = [];
  let missing = false;

  // If we don't have onboardingPermissions or sessionPermissions
  if(!onboardingPermissions || !sessionPermissions) {
    if(!containsAllCheck){
      return returnArr;
    }
    else if(containsAllCheck === true){
      return true;
    }
  }

  // Check that all three uis have camera permissions
  if(
    examPermissions &&
    onboardingPermissions.indexOf('camera') !== -1 &&
    sessionPermissions.indexOf('camera') !== -1 &&
    examPermissions.indexOf('camera') !== -1
  ) {
    returnArr.push('camera');
  } else{
    missing = true;
  }

  // Check that onboarding and sessionUI have remaining permissions
  if(
    onboardingPermissions.indexOf('microphone') !== -1 &&
    sessionPermissions.indexOf('microphone') !== -1
  ) {
    returnArr.push('microphone');
  } else{
    missing = true;
  }

  if(
    onboardingPermissions.indexOf('images') !== -1 &&
    sessionPermissions.indexOf('images') !== -1
  ) {
    returnArr.push('images');
  } else {
    missing = true;
  }

  if(
    onboardingPermissions.indexOf('popups') !== -1 &&
    sessionPermissions.indexOf('popups') !== -1
  ) {
    returnArr.push('popups');
  } else {
    missing = true;
  }

  if(!containsAllCheck){
    return returnArr;
  }
  else if(containsAllCheck){
    return missing;
  }
}

/**
 * toHours - Takes time in minutes and converts it to hours and minutes
 * @param {number} time - time in minutes to be converted to hours
 * @returns
 */
 export function toHours(time) {
  if (time >= 60) {
    return (
      <span>
        {parseInt(time / 60, 10)} hr
        {time % 60 > 0 && ` ${time % 60} min`}
      </span>
    );
  } else {
    return (
      <span>
        {time} min
      </span>
    );
  }
}

export function pTranslationArray(contentArray) {
  return contentArray.map(text => <p key={text}>{text}</p>);
}

export function liTranslationArray(contentArray) {
  return contentArray.map(text => <li key={text}>{text}</li>);
}

export class httpRequest {
    async get(url, headers = {}) {
        return await api.get(url, {
            headers
        });
    };

    async post(url, headers = {}, payload = {}) {
        return await api.post(url, payload, { headers });
    };

    async put(url, headers = {}, payload = {}) {
        return await api.put(url, payload, { headers });
    }

    async patch(url, headers = {}, payload = {}) {
        return await api.patch(url, payload, { headers });
    }

    async delete(url, headers = {}, payload = {}) {
        return await api.delete(url, {
            headers,
            data: payload
        });
    }
}