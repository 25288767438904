// React
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import TwinPane from '../TwinPane';

import './index.css';

export default function TestingInfo({ setNextEnabled }) {
  const { t: translate } = useTranslation();
  const translation = translate("testing_info");

  const renderLeftPane = () => {
    return (
      <div>
        <ul>
          {translation.steps.map((step, i) => {
            return <li key={`step-${i}`}>{step}</li>
          })}
        </ul>
      </div>
    )
  };

  const renderRightPane = () => {
    return (
      <div className='flex-container'>
          <iframe
            className='vimeo-iframe'
            src="https://player.vimeo.com/video/838799499?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="SmarterProctoring Remote Proctoring"
          >
          </iframe>
      </div>
    )
  }

  useEffect(() => {
    setNextEnabled(true);
    //eslint-disable-next-line
  }, [])

  return (
    <TwinPane leftPane={renderLeftPane()} rightPane={renderRightPane()} />
  )
}