import './index.css';
import TwinPane from '../TwinPane';
import { useTranslation } from 'react-i18next';
import React, {useEffect, useState, useRef, useImperativeHandle} from 'react';
import { pTranslationArray } from '../../helpers/Helpers';
import { ActionTypes } from '@smarterservices/twilio-sync-client';
import * as icons from '../Icons'
import {config} from "../../config";
import CountdownTimer from "../CountdownTimer";
import { getWaitTime } from './api';
import { FeatureFlag } from 'react-launch-darkly';

function ProctorReview(props, ref) {
  const { t: translate } = useTranslation();
  const translation = translate('proctor_review');
  const preparationSteps = translate('testing_info').steps;

  const { setNextEnabled, setShouldLaunchExam, addNonHybridSteps, removeNonHybridSteps, syncClient, throwError, params} = props;
  const { examSid, installSid, token, sessionSid } = params;

  const [proctorAssigned, setProctorAssigned] = useState(false);
  const [reviewInProgress, setReviewInProgress] = useState(false);
  const [reviewComplete, setReviewComplete] = useState(false);
  const [denied, setDenied] = useState(false);
  const [fallbackToAutomated, setFallbackToAutomated] = useState(false);
  const [inRetryLoop, setInRetryLoop] = useState(false);
  const [denialReason, setDenialReason] = useState('');
  const [shouldCalcWaitTime, setShouldCalcWaitTime] = useState(false);
  const [waitTime, setWaitTime] = useState(-1);
  const [provideWaitTimeEstimate, setProvideWaitTimeEstimate] = useState(true);
  const firstRender = useRef(true);
  const isRoutedInRetry = useRef(false);

  const actionTypes = new ActionTypes();

  useImperativeHandle(ref, () => {
    return{
      emitterCallback: (syncDoc) => emitterCallback(syncDoc)
    }
  })

  const endSession = () => {
    syncClient.addAction(actionTypes.extension.endSession())
  }

  useEffect(()=> {
    if (reviewComplete || fallbackToAutomated) {
      setNextEnabled(true);
    } else {
      setNextEnabled(false);
    }
  })

  useEffect(() => {
    if (inRetryLoop) {
      const routeInterval = setInterval(() => {
        if (isRoutedInRetry.current) {
          clearInterval(routeInterval);
        } else {
          routeStudent();
        }
      }, 15000);
    }
    // eslint-disable-next-line
  }, [inRetryLoop])

  const renderWhileYouWait = () => {
    return (
      <div>
        <p>{translation.get_ready}</p>
            <ul>
              {preparationSteps.map((step, i) =>
                <li key={`step-${i}`}>{step}</li>
              )}
            </ul>
      </div>
    )
  }

  const renderWelcomeText = () => {
    return (
      <div>
        <p className='center-text'>{translation.welcome}</p>
        <p>{translation.wait_info}</p>
      </div>
    )
  }

  const renderWaitTime = (flagValue) => {
    // Remove wait time estimate once review starts
    if (reviewInProgress) return null;

    if(provideWaitTimeEstimate){
      switch (flagValue) {
        case 'hide':
          return null;
        case 'show':
          setShouldCalcWaitTime(true);
          if (waitTime < 0) {
            // Since waitTime is initialized with -1, we don't want to show anything at first to avoid a flicker if the room is not providing wait times...
            return null;
          } else if (waitTime === 0) {
            return (
              <span className='waiting-time'>
                <p className='waiting-time--header'>{translation.estimated_wait_time}</p>
                <p className='waiting-time--body'><strong>{translation.review_starting_soon}</strong></p>
                <p>{translation.wait_time_info}</p>
              </span>
            )
          } else {
            return (
              <span className='waiting-time'>
                <p className='waiting-time--header'>{translation.estimated_wait_time}</p>
                <p className='waiting-time--body'><strong>{translation.wait_time_remaining.replace('%time%', waitTime)}</strong></p>
                <p>{translation.wait_time_info}</p>
              </span>
            )
          }
        case 'high-volume':
          setShouldCalcWaitTime(true);
          return (
            <span className='waiting-time'>
              <p className='waiting-time--body'><strong>{translation.static_high_volume_wait_message}</strong></p>
            </span>
          )
        default:
          return null;
      }
    }
    return null;
  }

  const handleAbandonment = (canFallback) => {
    if (!reviewComplete) {
      setReviewInProgress(false);
      throwError('Your proctor has lost connection. Please wait while we connect you to another proctor. This process could take up to 10 minutes');

      if (canFallback) {
        setFallbackToAutomated(true);
        addNonHybridSteps();
      } else {
        setInRetryLoop(true);
      }
    }
  }

  const emitterCallback = (syncDoc) => {
    if(syncDoc.connectedToProctor) {
      setProctorAssigned(true);
      setInRetryLoop(false);
    } else if (!syncDoc.connectedToProctor && !reviewComplete) {
      setProctorAssigned(false);
    }

    if(syncDoc.proctorReviewComplete) {
      switch(syncDoc.proctorReviewComplete.result) {
        case 'waiting':
          if (syncDoc.proctorReviewComplete.reason === 'in-review') {
            setReviewInProgress(true);
          } else if (syncDoc.proctorReviewComplete.reason === 'proctor-abandoned' && !syncDoc.connectedToProctor) {
            handleAbandonment(syncDoc.fallbackToAutomated);
          }
          break;
        case 'approved':
          setShouldLaunchExam(true);
          setReviewInProgress(true);
          setReviewComplete(true);
          break;
        case 'denied':
          setDenied(true);
          setDenialReason(syncDoc.proctorReviewComplete.reason)
          break;
        case 'reset':
          setReviewComplete(false);
          setReviewInProgress(false);
          break;
        default:
          break;
      }
    }
  }

  const routeStudent = () => {
    const options = {
      method: "PUT",
      body: JSON.stringify({}),
      headers: {
        token: token
      }
    };
    const url = `${config.apiUrl}/hybrid/students/installs/${installSid}/exams/${examSid}/sessions/${sessionSid}/route`;

    fetch(url, options)
        .then((response) => {
          response.json().then(result => {
            if (!result.success && result.error) {
              // We dont want to wait on any other actions to complete, so we will replace all actions to boot
              // out the student with the error message (Typically the error is that no proctors are available and
              // they could not be rerouted elsewhere)
              setInRetryLoop(false);
              syncClient.setSync({
                extensionActions: [{
                  action: 'endSession',
                  executor: 'extension',
                  endPopup: 'ErrorDialog',
                  requiredSyncDocField: 'syncDocCreationDate',
                  reason: result.error,
                  type: 'action'
                }]
              });
            } else if (!result.success && result.fallbackToAutomated) {
              setInRetryLoop(false);
              setFallbackToAutomated(true);
              isRoutedInRetry.current = true;
            } else if (!result.success) {
              // The student was not able to be routed, and they are not allowed to fallback to automated.
              // We need to try rerouting them for 10 minutes before kicking them out
              setInRetryLoop(true);
            } else if (result.success) {
              // The student connected with a hybrid proctoring room, so we need to remove the room scan and
              // verifyID steps because that is done by the proctor
              setInRetryLoop(false);
              isRoutedInRetry.current = true;
              removeNonHybridSteps();
            } else {
              // The student should never get into this state, but if they do, we will end the session saying that
              // their exam got into a corrupted state, so we cannot move forward and they should try again
              // we will just throw so it will have the same error message as catching any unhandled errors
              setInRetryLoop(false);
              isRoutedInRetry.current = true;
              throw new Error('Unhandled Error');
            }
          })

        }).catch(() => {
      syncClient.setSync({
        extensionActions: [{
          action: 'endSession',
          executor: 'extension',
          endPopup: 'ErrorDialog',
          requiredSyncDocField: 'syncDocCreationDate',
          reason: translation.error_routing,
          type: 'action'
        }]
      });
    });
  }

  const getEstimatedWaitTime = async () => {
    const response = await getWaitTime(installSid, examSid, sessionSid, token);
    if (!response) return;

    // Wait time is returned in seconds but we only want to show time in minutes
    const waitTime = Math.round(response.projectedWaitTime / 60);

    setWaitTime(waitTime);

    // If the room is not providing wait times, flip the variable to reflect that...
    if(!response.provideWaitTimeEstimate){
      setProvideWaitTimeEstimate(false);
    }
  }

  useEffect(()=> {
    if (syncClient) {
      if (firstRender.current) {
        firstRender.current = false;

        syncClient.setSync({ currentStep: "ProctorReview" });

        routeStudent();
      }
    }
    // eslint-disable-next-line
  }, [actionTypes.session, installSid, examSid, syncClient, sessionSid, token])

  useEffect(() => {
    let intervalId;
    if (provideWaitTimeEstimate && shouldCalcWaitTime && proctorAssigned && !reviewInProgress && !reviewComplete) {
      getEstimatedWaitTime();
      intervalId = setInterval(getEstimatedWaitTime, 30000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }
    //eslint-disable-next-line
  },[provideWaitTimeEstimate, shouldCalcWaitTime, proctorAssigned, reviewInProgress, reviewComplete])

  const renderLeftPane = () => {
    if (fallbackToAutomated) {
      return (
          <div>
            {pTranslationArray(translation.left_pane.fallback_to_automated)}
          </div>
      )
    } else if (inRetryLoop) {
      const timeToWait = 600; // 10 minutes in seconds
        return (
            <div>
              {pTranslationArray(translation.left_pane.in_retry_loop)}
              <CountdownTimer
                  countdownSeconds={timeToWait}
                  endTimerFunction={() => {
                    syncClient.setSync({
                      extensionActions: [{
                        action: 'endSession',
                        executor: 'extension',
                        endPopup: 'ErrorDialog',
                        requiredSyncDocField: 'syncDocCreationDate',
                        reason: translation.no_proctors_available,
                        type: 'action'
                      }]
                    });
                  }}
              />
            </div>
        )
    } else if (denied) {
      return (
        <div>
          <p>{`${translation.left_pane.denial_reason} ${denialReason || 'No reason given'}`}</p>
        </div>
      )
    } else if (reviewComplete) {
      return (
         <div>
          {pTranslationArray(translation.left_pane.review_complete)}
        </div>
      )
    } else if (proctorAssigned && reviewInProgress) {
      return (
        <div>
          {pTranslationArray(translation.left_pane.review_in_progress)}
        </div>
      )
    } else if (proctorAssigned) {
      return (
        <div>
          {renderWelcomeText()}

          {renderWhileYouWait()}
        </div>
      )
    } else {
      return (
        <div>
          {renderWelcomeText()}

          {renderWhileYouWait()}
        </div>
      )
    }

  }

  const renderRightPane = () => {
    if (denied) {
      return (
        <div className='column'>
          <icons.iconTimesCircle />
          <h2 className='icon-text'>{translation.denied}</h2>
          <button className='btn' onClick={endSession}>{translation.end_session}</button>
        </div>
      )
    } else if (fallbackToAutomated) {
      return(
          <div className = "column pass">
            < icons.iconCheckCircle/>
            <h2> {translation.right_pane.success} </h2>
          </div>
      )
    } else {
      return (
        <div className='flex-container center'>
          <FeatureFlag
            flagKey='show-review-wait-time'
            renderFeatureCallback={renderWaitTime}
          />
          <div className="tally-loader">
            <ol className="tally">
              <li key={1}>
                <strong className="tally__title">{translation.proctor_assigned}</strong>
                <span className={`tally__status ${proctorAssigned ? 'completed' : 'in-progress'}`}></span>
                <span className="meta"></span>
              </li>
              <li key={2}>
                <strong className="tally__title">{translation.review_in_progress}</strong>
                <span className={`tally__status ${reviewInProgress ? 'completed' : 'in-progress'}`}></span>
                <span className="meta"></span>
              </li>
              <li key={3}>
                <strong className="tally__title">{translation.review_complete}</strong>
                <span className={`tally__status ${reviewComplete ? 'completed' : 'in-progress'}`}></span>
                <span className="meta"></span>
              </li>
            </ol>
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <TwinPane leftPane={renderLeftPane()} rightPane={renderRightPane()} />
    </>
  );
} export default React.forwardRef(ProctorReview);