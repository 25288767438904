import './index.css';
import React, {useEffect, useRef} from 'react';

// Audio functions
import { createAudioMeter } from './VolumeMeter';

function Microphone(props, ref) {
  const stream = useRef(null);

  // This useEffect will work like componentDidMount because of the empty
  // dependency array that is passed after the function.
  // Any code inside of the return function can be used like componentWillUnmount.
  useEffect(() => {

    initMicrophone();

    return () =>  {
      killMicrophone();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=> {

    initMicrophone();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.microphone]);

  const killMicrophone = () => {

    // Kill the camera stream on unmount
    stream?.current?.getTracks().forEach(track => {
      track.stop();
    })
  }

  const initMicrophone = () => {
    const { microphone } = props;
    const { deviceId } = JSON.parse(microphone);

    killMicrophone();

    navigator.mediaDevices.getUserMedia({audio: {deviceId}})
      .then(media => {
        stream?.current?.getTracks().forEach(track => {
          track.stop();
        });
        stream.current = media;
        visualize(media);
      })
      .catch(error => {
        console.error(error);
      });
  }

  const visualize = (stream) => {
    const audioCtx =  new (window.AudioContext)();
    const src = audioCtx.createMediaStreamSource(stream);
    const meter = createAudioMeter(audioCtx);

    src.connect(meter);

    const canvas = document.querySelector('.microphone__visualizer');
    const canvasCtx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;

    const volumeCheck = setInterval(() => {
      const audioLevel = meter.volume * 100;
      const hasAudio = audioLevel >= 1;

      if(hasAudio) {
        props.onAudioDetected();
        clearInterval(volumeCheck);
      }

    }, 100);

    draw();

    function draw() {
      canvasCtx.clearRect(0, 0, width, height);
      canvasCtx.fillStyle = 'lime';

      canvasCtx.fillRect(0, 0, width, meter.volume * height * 5);

      requestAnimationFrame(draw);
    }
  }

    return (
      <div className="microphone">
        <audio className="microphone__stream" muted={true}></audio>
        <canvas className="microphone__visualizer"></canvas>
      </div>
    );
} export default React.forwardRef(Microphone);