import { useEffect } from "react";

export default function Zendesk(props) {

  useEffect(()=> {
    const renderZDChat = () => {
      const {syncDoc} = props;
      const zdScript = document.querySelector('#ze-snippet');

      if(!zdScript) {
        let zdc = document.createElement('script');
        zdc.id = 'ze-snippet';
        zdc.type = 'text/javascript';
        zdc.async = true;
        zdc.src = 'https://static.zdassets.com/ekr/snippet.js?key=a3206868-bdac-4af5-a921-ee51087a581e';

        zdc.onload = function() {
          window.zE(function(){
            if (syncDoc.sessionStart && syncDoc.sessionStart.user) {
              window.zE.identify({
                name: `${syncDoc.sessionStart?.user?.firstName || ''} ${syncDoc.sessionStart?.user?.lastName || ''}`,
                email: syncDoc.sessionStart?.user?.email || '',
                organization: ''
              });
          }

            window.zE('webWidget', 'updateSettings', {
              webWidget: {
                offset: {
                  vertical: '44px'
                }
              }
            });
          });
        }

        document.body.appendChild(zdc);
      }

      return null;
    };

    renderZDChat();
  });
}