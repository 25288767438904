import { useTranslation } from 'react-i18next';
import TwinPane from '../TwinPane';
import * as icons from '../../icons/Icons';
import { pTranslationArray } from '../../helpers/Helpers';
import {useEffect, useState} from 'react';

export default function AgreementsCheck(props) {
    const { t: translate } = useTranslation();
    const translation = translate('agreements_check');
    const { setNextEnabled, params, syncClient } = props;
    const [agreementsComplete, setAgreementsComplete] = useState(false);

    useEffect(() => {
        setNextEnabled(false)
        syncClient.setSync({ currentStep: "AgreementsCheck" })
        document.addEventListener('agreementsComplete', () => complete());
        //eslint-disable-next-line
    },[])

    const complete = () => {
        if (window.Agreements.agrFetched ){
            syncClient.setSync({ agreementsComplete: true })
            setNextEnabled(true)
            setAgreementsComplete(true);
        }
    }

    useEffect(() => {
            renderAgreements();

    //eslint-disable-next-line
    },[syncClient])

    const renderAgreements = () => {
        const agrScript = document.querySelector('#agr-ui');
        if (!agrScript) {
            let agr = document.createElement('script');
            agr.id = 'agr-ui';
            agr.type = 'text/javascript';
            agr.async = true;
            agr.src = 'https://s3.amazonaws.com/cdn.smarterservices.com/agreements/js/agreements.min.js';

            agr.onload = function(){
                checkAgreements('production');
            };

            document.body.appendChild(agr);
        }
        return null;
    }

    const checkAgreements = (env) => {

        let agrOpts = {
            mode: 'inline',
            containerId: 'agreement-container',
            location: 'extension-recording-consent',
            target: Date.now(),
            account: params.installSid
        }
        if (env === 'production') {
            agrOpts.application = 'AP6800b9e2e1794dbbbf6ad866194821d3';
            window.Agreements.setup(agrOpts);
        } else {
            agrOpts.application = 'APfb4f76ee1dc24d058048fdaed04f56f1';
            window.Agreements.setup(agrOpts);
        }
    }


    const renderLeftPane = () => {
        if (agreementsComplete){
            return(
                <div>
                    { pTranslationArray(translation.content.complete) }
                </div>
            );
        }
        else {
            return(
                <div>
                    { pTranslationArray(translation.content.incomplete) }
                </div>
            );
        }

    };

    const renderRightPane = () => {
        if (agreementsComplete){
            return(
                <div className='flex-div'>
                    <icons.iconCheckCircle/>
                    <h2>{(translation.content.success)}</h2>
                </div>
            );
        }
        else{
            return(
                <div
                    className="loader--async panel__agreement"
                    id="agreement-container"
                />
            );
        }
    };


    return(
        <>
            <TwinPane leftPane={renderLeftPane()} rightPane={renderRightPane()}/>
        </>
    );
}