import './index.css';
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

export default function CountdownTimer(props) {
    const { t: translate } = useTranslation();
    const translation = translate('countdown_timer');
    const [counter, setCounter] = useState(-1);

    const initCountdownTimer = () => {
        const { countdownSeconds } = props;
        setCounter(countdownSeconds);
    }

    useEffect(() => {
        initCountdownTimer();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // If the counter has time then we should subtract a second every second until we reach zero
        if (counter > 0) {
            setTimeout(() => setCounter(counter - 1), 1000);
        } else if (counter === 0) {
            const { endTimerFunction } = props;
            endTimerFunction();
        }
        // eslint-disable-next-line
    }, [counter]);

    const formatTimer = () => {
        const minutes = Math.floor(counter / 60);
        const seconds = counter % 60;
        let formattedSeconds = seconds;
        if (seconds < 10) {
            formattedSeconds = `0${seconds}`;
        }

        if (minutes === 0) {
            return `${translation.time_remaining} ${formattedSeconds}`;
        } else {
            return `${translation.time_remaining} ${minutes}:${formattedSeconds}`;
        }
    };


    return (
        <div id={"sp_countdown_timer"}>
            {formatTimer()}
        </div>
    );
}
